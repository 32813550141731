<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>


          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("firstName") }}</label>
            <b-form-input
                v-model="filter.like.firstName"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("lastName") }}</label>
            <b-form-input
                v-model="filter.like.lastName"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("Email") }}</label>
            <b-form-input
                v-model="filter.like.email"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("Phone") }}</label>
            <b-form-input
                v-model="filter.like.phone"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="2" class="mb-md-0 mb-2 mt-2">

            <!-- Minimum slider for number of bookings -->
            <b-form-group label="Minimum Bookings">
              <input
                  type="range"
                  v-model="filter.gte.numberOfContracts"
                  class="custom-range"
                  :min="2"
                  :max="50"
                  :step="1"
              />
              <b-form-text>Selected Minimum: {{ filter.gte.numberOfContracts }}</b-form-text>
            </b-form-group>

            <!-- Maximum slider for number of bookings -->
            <b-form-group label="Maximum Bookings">
              <input
                  type="range"
                  v-model="filter.lte.numberOfContracts"
                  class="custom-range"
                  :min="2"
                  :max="50"
                  :step="1"
              />
              <b-form-text>Selected Maximum: {{ filter.lte.numberOfContracts }}</b-form-text>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Clients') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  @input="refresh"

                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="filter.like.search"
                    class="d-inline-block"
                    :placeholder="msg('Search')"
                />
                <span class="mr-1"></span>

              </div>
            </b-col>
          </b-row>

        </div>
        <!--Table-->
        <b-table
            ref="priorityClient-table"
            striped
            hover
            responsive
            v-bind:items="priorityClients"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >

          <template #cell(numberOfContracts)="data">
            <b-badge  style="font-size: 15px;font-weight: bold" variant="dark">{{ (data.value) }}</b-badge>
          </template>

          <template #cell(buid)="data">
            <b-badge variant="primary">{{ getBranchName(data.value) }}</b-badge>
          </template>


          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>
          <template #cell(createdDate)="data">
            {{
              $moment(data.value, 'YYYY-MM-DD HH:mm').add($moment().utcOffset(), 'minutes').format("DD.MM.YY HH:mm:ss")
            }}
          </template>

        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'PriorityClients',
  components: {},
  data() {
    return {

      loading: true,
      amount: 10,
      priorityClients: [],
      fields: [],
      priorityClientId: null,
      filter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", realEstateArea: {}},
        lte: {numberOfContracts: 100},
        gte: {numberOfContracts: 2},
        in: {}
      },
      sort: {
        by: 'id',
        desc: true,
        direction: 'desc'
      },
      statusVariants:
          {"NEW": "primary", "ACTIVE": "success", "EXPOSE": "warning", "DISABLED": "danger"},

      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },

      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    file: {
      handler() {
        this.uploadFile()
      }
    }, fileClient: {
      handler() {
        this.uploadFileClient()
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getBranchName']),


  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('priorityClient', ['getAllPriorityClients']),


    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this
      this.fields = [


        {
          key: 'clientId',
          label: $this.msg('ID'),
          sortable: true
        },

        {
          key: 'numberOfContracts',
          label: $this.msg('Bookings'),
          sortable: true
        },

        {
          key: 'firstName',
          label: $this.msg('First Name'),
          sortable: true
        },
        {
          key: 'lastName',
          label: $this.msg('Last Name'),
          sortable: true
        },

        {
          key: 'email',
          label: $this.msg('Email'),
          sortable: true
        },


        {
          key: 'phone',
          label: $this.msg('Phone'),
          sortable: true
        },

        {
          key: 'createdDate',
          label: $this.msg('creationDate'),
          sortable: true
        },

        {
          key: 'buid',
          label: $this.msg('Branch'),
          sortable: true
        },
      ]
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    infoFeature(item, index, button) {
      this.featureInfoModal.title = `Row index: ${index}`
      this.featureInfoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.featureInfoModal.id, button)
    },
    copyId(id) {
      console.log("the id copied was: ", id)
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    resetFeatureInfoModal() {
      this.$root.$emit('bv::hide::modal', this.featureInfoModal.id)
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.getAllPriorityClients({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: $this.sort.by,
        sortby: $this.sort.direction,
      }).then(this.onPriorityClientsRetrieve, (error) => $this.error = error)

    },
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    onPriorityClientsRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.priorityClients = data["PriorityClients"];
      this.loading = false;
    },
    onPriorityClientSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.priorityClient = true
      }
    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(priorityClient) {
      this.$root.$emit('crm::client::select', priorityClient.clientId)
    },

    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.priorityClient = false;
    }
  }
}
</script>

<style>
input[type="range"].custom-range {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #8dd90b;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

input[type="range"].custom-range:hover {
  opacity: 1;
}

input[type="range"].custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

input[type="range"].custom-range::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

input[type="range"].custom-range::-ms-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

input[type="range"].custom-range:focus {
  outline: none;
}

/* Optional: Add focus style */
input[type="range"].custom-range:focus::-webkit-slider-thumb {
  background: #0056b3;
}

input[type="range"].custom-range:focus::-moz-range-thumb {
  background: #0056b3;
}

input[type="range"].custom-range:focus::-ms-thumb {
  background: #0056b3;
}
</style>
